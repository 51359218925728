export async function injectSpritesheet(uri: string) {
  try {
    const response = await fetch(uri);
    const container = document.createElement("div");
    container.innerHTML = await response.text();
    const sprite = container.firstChild;
    document.body.insertBefore(sprite!.cloneNode(true), document.body.firstChild);
  } catch (error) {
    return error;
  }
}
