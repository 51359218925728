let container: any;
let unsupportedBrowserPageElement: HTMLDivElement;

type text = {
  [key: string]: string;
};

const i18nTexts: { [key: string]: text } = {
  en: {
    useChrome: 'The Google Chrome browser should be used to access TC3.',
    downloadChrome: 'Download Google Chrome',
  },
  fr: {
    useChrome: 'Vous devez utiliser le navigateur Google Chrome pour accéder à TC3.',
    downloadChrome: 'Télécharger Google Chrome',
  },
};

export async function bootstrap() {
  container = document.getElementById('viewport');
  unsupportedBrowserPageElement = document.createElement('div');
  unsupportedBrowserPageElement.style.flex = '1';

  const language = localStorage.getItem('lang') || 'en';
  const texts = i18nTexts[language];
  const { useChrome, downloadChrome } = texts;

  unsupportedBrowserPageElement.innerHTML = `
    <div style="justify-content: center; align-items: center; display: flex; height: 100%; padding-bottom: 15%; flex-direction: column;">
      <img style="max-width: 96px; margin:1rem;" src="https://www.google.com/chrome/static/images/chrome-logo.svg" />
      <p style='font-family: ProximaNova; font-size: 24px; margin:1rem;'>${useChrome}</p>
      <a href="https://www.google.com/chrome/" style="cursor: pointer; font-family: ProximaNova; font-size: 18px; color: #4f7cb4;">${downloadChrome}</a>
    </div>
  `;

  unsupportedBrowserPageElement.style.display = 'none';
  container.innerHTML = '';
  container.appendChild(unsupportedBrowserPageElement);
}

export async function mount() {
  unsupportedBrowserPageElement.style.display = 'block';
  container.style.opacity = '1';
}

export async function unmount() {
  unsupportedBrowserPageElement.style.display = 'none';
}
