import Auth from './auth/auth';

let container: any;
let unauthorizedPageElement: HTMLDivElement;

const auth = new Auth();

const titleTexts: { [key: string]: string } = {
  en: 'Access Denied',
  fr: 'Accès refusé',
};

const mainTexts: { [key: string]: string } = {
  en: 'Oops! You do not have permission to access the requested page. If you think you should have access, please contact your administrator.',
  fr: 'Oups ! Vous n’êtes pas autorisé à accéder à la page demandée. Si vous pensez que vous devriez avoir accès, veuillez contacter votre administrateur.',
};

const returnToHomeTexts: { [key: string]: string } = {
  en: 'Click here to return to the home page',
  fr: "Cliquez ici pour retourner à l'écran d'accueil",
};

export async function bootstrap() {
  container = document.getElementById('viewport');
  unauthorizedPageElement = document.createElement('div');
  unauthorizedPageElement.style.flex = '1';

  const language = localStorage.getItem('lang') || 'en';
  const titleText = titleTexts[language];
  const mainText = mainTexts[language];
  const returnToHomeText = returnToHomeTexts[language];

  unauthorizedPageElement.innerHTML = `
    <div style="font-family: ProximaNova; justify-content: center; align-items: center; display: flex; height: 100%; padding-bottom: 15%; flex-direction: column;">
      <h1 style="font-size: 28px; font-weight: bold; margin-bottom: 10px">${titleText}</h1>
      <p style=" font-size: 24px; margin-bottom: 30px;">${mainText}</p>
      
      <a id="returnHomeBtn" style="margin-bottom: 5px; cursor: pointer; font-size: 18px; color: #4f7cb4;">${returnToHomeText}</a>
    </div>
  `;

  unauthorizedPageElement.style.display = 'none';
  container.innerHTML = '';
  container.appendChild(unauthorizedPageElement);

  document.getElementById("returnHomeBtn")?.addEventListener('click', () =>{
    const userHasNoRoles = !Auth.getUser()?.groups?.length;
    if(Auth.isLoggedIn() && userHasNoRoles){
      auth.logout();
      return;
    }
    window.location.replace('/');
  })
}

export async function mount() {
  unauthorizedPageElement.style.display = 'block';
  container.style.opacity = '1';
}

export async function unmount() {
  unauthorizedPageElement.style.display = 'none';
}
