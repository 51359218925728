let container: any;
let unavailablePageElement: HTMLDivElement;

const mainTexts: { [key: string]: string } = {
  en: 'A technical error has occurred. Please contact our technical support.',
  fr: 'Une erreur technique est survenue, veuillez contacter le soutien technique.',
};

const returnToHomeTexts: { [key: string]: string } = {
  en: 'Click here to return to the home page',
  fr: "Cliquez ici pour retourner à l'écran d'accueil",
};

export async function bootstrap() {
  container = document.getElementById('viewport');
  unavailablePageElement = document.createElement('div');
  unavailablePageElement.style.flex = '1';

  const language = localStorage.getItem('lang') || 'en';
  const mainText = mainTexts[language];
  const returnToHomeText = returnToHomeTexts[language];

  unavailablePageElement.innerHTML = `
    <div style="justify-content: center; align-items: center; display: flex; height: 100%; padding-bottom: 15%; flex-direction: column;">
      <p style="font-family: ProximaNova; font-size: 24px; margin-bottom: 30px;">${mainText}</p>
      <a href="/" style="cursor: pointer; font-family: ProximaNova; font-size: 18px; color: #4f7cb4;">${returnToHomeText}</a>
    </div>
  `;

  unavailablePageElement.style.display = 'none';
  container.innerHTML = '';
  container.appendChild(unavailablePageElement);
}

export async function mount() {
  unavailablePageElement.style.display = 'block';
  container.style.opacity = '1';
}

export async function unmount() {
  unavailablePageElement.style.display = 'none';
}
