import { HubConnectionState, HubConnectionBuilder, RetryContext } from '@microsoft/signalr';

const defaultRetryDelays = [0, 2000, 10000, 30000];

const getNextRetryDelay = (retryContext: RetryContext) => {
  const reason: any = retryContext.retryReason;
  console.log('Trying to reconnect to SignalR:', retryContext.retryReason.message);
  if (reason.statusCode === 401 && !document.hidden) {
    window.location.href = '/401';
  }

  if (retryContext.previousRetryCount >= defaultRetryDelays.length) {
    return null;
  }

  return defaultRetryDelays[retryContext.previousRetryCount];
};

const startConnection = (connection: any) => {
  connection
    .start()
    .then(() => {
      console.log('Connected to hub');
    })
    .catch((e: any) => console.log('Connection failed: ', e));
};

export const getSignalRHubConnection = () => {
  const url = `${process.env.REACT_APP_API_URL}/tc3-workflow/api/v1/notifications`;

  const hubConnection = new HubConnectionBuilder()
    .withUrl(url, { accessTokenFactory: () => localStorage.getItem('access_token')! })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: getNextRetryDelay,
    })
    .build();

  startConnection(hubConnection);

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && hubConnection.state !== HubConnectionState.Connected) {
      startConnection(hubConnection);
    }
  });

  return hubConnection;
};
