let container: any;
let notFoundPageElement: HTMLDivElement;

const mainTexts: { [key: string]: string } = {
  en: "Oops, the page you're looking for doesn't exist.",
  fr: "Oups, la page que vous recherchez n'existe pas.",
};

const returnToHomeTexts: { [key: string]: string } = {
  en: 'Click here to return to the home page',
  fr: "Cliquez ici pour retourner à l'écran d'accueil",
};

export async function bootstrap() {
  container = document.getElementById('viewport');
  notFoundPageElement = document.createElement('div');
  notFoundPageElement.style.flex = '1';

  const language = localStorage.getItem('lang') || 'en';
  const mainText = mainTexts[language];
  const returnToHomeText = returnToHomeTexts[language];

  notFoundPageElement.innerHTML = `
    <div style="justify-content: center; align-items: center; display: flex; height: 100%; padding-bottom: 15%; flex-direction: column;">
      <p style="font-family: ProximaNova; font-size: 24px; margin-bottom: 30px;">${mainText}</p>
      <a href="/" style="cursor: pointer; font-family: ProximaNova; font-size: 18px; color: #4f7cb4;">${returnToHomeText}</a>
    </div>
  `;

  notFoundPageElement.style.display = 'none';
  container.innerHTML = '';
  container.appendChild(notFoundPageElement);
}

export async function mount() {
  notFoundPageElement.style.display = 'block';
  container.style.opacity = '1';
}

export async function unmount() {
  notFoundPageElement.style.display = 'none';
}
